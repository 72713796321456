import { useEffect } from "react";
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import Slider from "../components/slider";

function Home() {
  useEffect(() => {
    document.querySelector("body")?.setAttribute("color", "color_1");
  }, []);
  return (
    <>
	<Header />
	  <div className="page-content bg-white" id="top">
        <Slider />
      </div>
	  <Footer />
    </>
  );
}

export default Home;
