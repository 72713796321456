import FileUploader from "../file-uploader";

type UploadSubtitleFileStepProps = {
  mediaHandler: (files: FileList | null) => void;
  cancleHandler: () => void;
};

const UploadSubtitleFileStep = ({
  mediaHandler,
  cancleHandler,
}: UploadSubtitleFileStepProps) => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-8">
          <div className="banner-content">
            <h6
              data-wow-duration="1s"
              data-wow-delay="0.5s"
              className="wow fadeInUp sub-title text-primary"
            ></h6>
            <h2
              data-wow-duration="1.2s"
              data-wow-delay="1s"
              className="wow text-primary "
            >
              {"Step 2: "}
              <span className="fadeInUp" style={{ color: "black" }}>
                Upload Your Subtitle or Skip
              </span>{" "}
            </h2>
            <p
              data-wow-duration="1.4s"
              data-wow-delay="1.5s"
              className="wow fadeInUp m-b30"
            >
              You can upload a subtitle from your local computer.
              {/*to watch your*/}
              {/*media with that and serach the new vocabularies' definitions. */}
              This file is used localy, and does not be uploaded on our server
              through the Internet to keep your privacy.
            </p>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <FileUploader onFileSelect={mediaHandler} />
              {/*<div>*/}
                <button
                    name="submit"
                    type="submit"
                    value="Submit"
                    className="btn btn-primary rounded-md"
                    onClick={cancleHandler}
                >
                  Skip
                </button>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadSubtitleFileStep;
